// =======================================
//   List of variables for Preset color
// =======================================
// theme color
$theme-500: #ff3366;

// Gray color
$white: #ffffff;
$gray-100: #f8f9fa;
$gray-200: #f3f5f7;
$gray-300: #dbe0e5;
$gray-400: #bec8d0;
$gray-500: #8996a4;
$gray-600: #5b6b79;
$gray-700: #3e4853;
$gray-800: #1d2630;
$gray-900: #131920;
$black: #000000;

// blue color
$blue-100: #757bb1;
$blue-200: #5e65a4;
$blue-300: #474f97;
$blue-400: #30398a;
$blue-500: #1A237E;
$blue-600: #171f71;
$blue-700: #141c64;
$blue-800: #121858;
$blue-900: #0f154b;

// indigo color
$indigo-100: #d1b7fb;
$indigo-200: #b388f9;
$indigo-300: #9458f6;
$indigo-400: #7d34f4;
$indigo-500: #6610f2;
$indigo-600: #5e0ef0;
$indigo-700: #530cee;
$indigo-800: #4909ec;
$indigo-900: #3805e8;

// purple color
$purple-100: #d1c4e9;
$purple-200: #b39ddb;
$purple-300: #9575cd;
$purple-400: #7e58c2;
$purple-500: #673ab7;
$purple-600: #5f34b0;
$purple-700: #542ca7;
$purple-800: #4a259f;
$purple-900: #391890;

$pink-100: #f8c5dd;
$pink-200: #f49fc6;
$pink-300: #ef78af;
$pink-400: #eb5b9d;
$pink-500: #e83e8c;
$pink-600: #e53884;
$pink-700: #e23079;
$pink-800: #de286f;
$pink-900: #d81b5c;

$red-100: #f5bebe;
$red-200: #ee9393;
$red-300: #e76767;
$red-400: #e14747;
$red-500: #dc2626;
$red-600: #d82222;
$red-700: #d31c1c;
$red-800: #ce1717;
$red-900: #c50d0d;

$orange-100: #fed8b9;
$orange-200: #febf8a;
$orange-300: #fea55b;
$orange-400: #fd9137;
$orange-500: #fd7e14;
$orange-600: #fd7612;
$orange-700: #fc6b0e;
$orange-800: #fc610b;
$orange-900: #fc4e06;

$yellow-100: #f7dcb3;
$yellow-200: #f2c580;
$yellow-300: #edad4d;
$yellow-400: #e99c26;
$yellow-500: #e58a00;
$yellow-600: #e28200;
$yellow-700: #de7700;
$yellow-800: #da6d00;
$yellow-900: #d35a00;

$green-100: #c0e5d9;
$green-200: #96d4bf;
$green-300: #6bc2a5;
$green-400: #4cb592;
$green-500: #2ca87f;
$green-600: #27a077;
$green-700: #21976c;
$green-800: #1b8d62;
$green-900: #107d4f;

$teal-100: #b3d9d9;
$teal-200: #80c0c0;
$teal-300: #4da6a6;
$teal-400: #269393;
$teal-500: #008080;
$teal-600: #007878;
$teal-700: #006d6d;
$teal-800: #006363;
$teal-900: #005050;

$cyan-100: #c5eff3;
$cyan-200: #9fe4eb;
$cyan-300: #78d9e2;
$cyan-400: #5bd1dc;
$cyan-500: #3ec9d6;
$cyan-600: #38c3d1;
$cyan-700: #30bccc;
$cyan-800: #28b5c6;
$cyan-900: #1ba9bc;
